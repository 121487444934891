<template>
  <modal-form
    :key="`hsCodesRejection_${currentCategory}`"
    :title="$t('views.profileVerification.rejectionReason')"
    :fields="fields"
    :loading="loading"
    :visible="modalVisible"
    :cancelButton="{type: 'secondary', visible: true}"
    @close-modal="modalVisible = false"
    @submit-form="reject"
  />
  <div v-if="!$_.isEmpty(company)">
    <a-row :gutter="15">
      <a-col :md="24" :xs="24">
        <sdCards headless>
          <a-row class="current-step">
            <a-col :xs="24" :sm="8">
              <h3>{{$t('views.registration.country')}}:</h3>
                {{$_.find(company.labelledCountriesCoverage, (country) => country.value === currentCountry)?.label}}
            </a-col>
          </a-row>
        </sdCards>
      </a-col>
      <a-col :md="24" :xs="24">
        <template v-for="category in categories" :key="category">
          <sdCards :title="$t(`models.priceCategory.attributes.categoryTypes.${category}`)">
            <price-list-table
              v-if="category !== 'hs_code_list'"
              :currentCountry="currentCountry"
              :currentCategory="category"
            />
            <hs-price-list-table
              v-else
              :currentCountry="currentCountry"
              :currentCategory="category"
              :companyId="companyId"
            />

            <div class="price-list-step-status">
              <h3 class="display-inline">{{$t('models.company.attributes.status')}}: </h3>
              {{categoryStatuses[category]?.status}}
              <br />
              <h3 v-if="categoryStatuses[category]?.status === 'rejected'" class="display-inline">
                {{$t('models.companyProfileStep.attributes.rejectionReason')}}:
              </h3>
              {{categoryStatuses[category]?.[$_.snakeCase('rejectionReason')]}}
            </div>

            <template #button>
              <sd-button
                v-if="categoryStatuses[category]?.status !== 'rejected'"
                class="btn-icon decision-icon"
                type="default"
                shape="circle"
                :tooltip="$t('actions.reject')"
                @click="openRejectionModal(category)"
              >
                <sd-feather-icons type="x-circle" size="20" :stroke="theme['danger-color']" />
              </sd-button>
              <sd-button
                v-if="categoryStatuses[category]?.status !== 'approved'"
                class="btn-icon decision-icon"
                type="default"
                shape="circle"
                :tooltip="$t('actions.approve')"
                @click="approve(category)"
              >
                <sd-feather-icons
                  class="decision-icons" type="check-circle" size="20" :stroke="theme['success-color']" />
              </sd-button>
             </template>
          </sdCards>
        </template>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  computed, defineComponent, reactive, ref, watch
} from 'vue';
import {useStore} from 'vuex';
import VueTypes from 'vue-types';
import _ from 'lodash';
import PriceListTable from '@/components/companyVerification/PriceListTable';
import HsPriceListTable from '@/components/companyVerification/HSPriceListTable';
import ModalForm from '@/components/modals/ModalForm';
import {useI18n} from 'vue-i18n';
import {required} from '@/helpers/validationRules';
import {theme} from '@/config/theme/themeVariables';

export default defineComponent({
  name: 'AgentPriceListTableVerification',
  emits: ['next', 'previous', 'updatePriceListStatuses'],
  components: {
    ModalForm,
    PriceListTable,
    HsPriceListTable
  },
  props: {
    companyId: VueTypes.string,
    status: VueTypes.oneOfType([VueTypes.string, VueTypes.func]),
    rejectionReason: VueTypes.oneOfType([VueTypes.string, VueTypes.func])
  },
  setup(props, context) {
    const {getters, dispatch} = useStore();
    const {t} = useI18n();

    const loading = ref(false);
    const currentCountry = ref('');
    const company = computed(() => getters.company);
    const companyPriceLists = computed(() => getters.companyPriceLists);
    const countriesCoverage = ref([]);
    const categories = computed(() => _.keys(_.get(getters.companyPriceLists, currentCountry.value)));
    const profileSteps = computed(() => getters.companyProfileSteps);
    const categoryStatuses = reactive({});
    watch(() => [profileSteps, currentCountry], () => {
      if (_.isEmpty(profileSteps.value) || !currentCountry.value) return;

      Object.assign(categoryStatuses, {});

      _.forEach(profileSteps.value['priceListSteps'], (step) => {
        if (step.countryCode !== currentCountry.value) return;

        categoryStatuses[step.category] = {
          status: step.status,
          [_.snakeCase('rejectionReason')]: step.rejectionReason,
          id: step._jv.id
        };
      });
      context.emit('updatePriceListStatuses', categoryStatuses);
    }, {immediate: true, deep: true});

    const fields = {
      reason: {
        type: 'textArea',
        fieldValue: '',
        placeHolder: t('views.profileVerification.rejectionReasonPlaceholder'),
        rules: [required],
        class: 'mb-20'
      }
    };

    const nextCountryIndex = computed(() => _.indexOf(countriesCoverage.value, currentCountry.value) + 1);
    const hasNextCountry = computed(() => nextCountryIndex.value < countriesCoverage.value.length);

    const handleNext = async () => {
      if (!hasNextCountry.value) return context.emit('next');

      currentCountry.value = countriesCoverage.value[nextCountryIndex.value];
    };

    const previousCountryIndex = computed(() => _.indexOf(countriesCoverage.value, currentCountry.value) - 1);
    const hasPreviousCountry = computed(() => previousCountryIndex.value >= 0);

    const handlePrevious = () => {
      if (hasPreviousCountry.value) {
        currentCountry.value = countriesCoverage.value[previousCountryIndex.value];
        return;
      }

      context.emit('previous');
    };

    watch(() => props.companyId, async (value) => {
      if (value) {
        await dispatch('toggleLoading', true);
        await Promise.all([
          dispatch('getCompanyPriceLists', {companyId: props.companyId})
        ]);
        await dispatch('toggleLoading', false);

        countriesCoverage.value = _.keys(companyPriceLists.value);
        currentCountry.value = countriesCoverage.value[0];
      }
    }, {immediate: true, deep: true});

    const modalVisible = ref(false);
    const currentCategory = ref();

    const openRejectionModal = (category) => {
      currentCategory.value = category;
      modalVisible.value = true;
    };

    const reject = ({reason}) => {
      categoryStatuses[currentCategory.value].status = 'rejected';
      categoryStatuses[currentCategory.value][_.snakeCase('rejectionReason')] = reason;
      modalVisible.value = false;

      context.emit('updatePriceListStatuses', categoryStatuses);
    };

    const approve = (category) => {
      categoryStatuses[category].status = 'approved';
      categoryStatuses[category][_.snakeCase('rejectionReason')] = '';
      context.emit('updatePriceListStatuses', categoryStatuses);
    };

    return {
      company,
      currentCountry,
      loading,
      fields,
      categories,
      categoryStatuses,
      modalVisible,
      currentCategory,
      theme,
      handleNext,
      handlePrevious,
      approve,
      reject,
      openRejectionModal
    };
  }
});
</script>

<style lang="scss" scoped>
.current-step h3 {
  display: inline-block
}
</style>
