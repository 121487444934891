<template>
  <table-style-wrapper>
    <table-wrapper class="table-responsive">
      <a-table
        :columns="columns"
        :data-source="prices"
        :pagination="false"
        :rowKey="(record) => record._jv.id"
      >
      <template #duties="{record}">
       % {{record.duties}}
      </template>
      <template #vat="{record}">
       % {{record.vat}}
      </template>
      </a-table>
    </table-wrapper>
  </table-style-wrapper>
</template>

<script>
import {
  computed, defineComponent, watch
} from 'vue';
import {TableStyleWrapper} from '@/components/tables/style.js';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {useI18n} from 'vue-i18n';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';
import _ from 'lodash';
import {priceListApprovalColumns} from '@/constants';

export default defineComponent({
  name: 'HSPriceListTable',
  components: {
    TableWrapper,
    TableStyleWrapper
  },
  props: {
    currentCountry: VueTypes.string,
    currentCategory: VueTypes.string,
    companyId: VueTypes.string
  },
  setup(props) {
    const {getters, dispatch} = useStore();
    const {t} = useI18n();

    const hsCodesColumns = computed(() => getters.hsCodesColumns);
    const prices = computed(() => {
      if (props.currentCountry && props.currentCategory) {
        const priceList = getters.companyPriceLists;
        return priceList[props.currentCountry][props.currentCategory];
      } else {
        return [];
      }
    });

    watch(() => props.currentCountry, (code) => {
      if (props.currentCategory === _.snakeCase('hsCodeList')) {
        dispatch('getHsCodesColumns', {
          params: {[_.snakeCase('countryCode')]: code},
          companyId: props.companyId
        });
      }
    }, {immediate: true});

    const columns = computed(() => [
      {
        title: t('models.priceCategory.attributes.hsCode'),
        dataIndex: '_jv.relationships.priceCategoryItem.hsCode'
      },
      {
        title: t('models.priceList.attributes.duties'),
        dataIndex: 'duties',
        slots: {customRender: 'duties'}
      },
      {
        title: t('models.priceList.attributes.vat'),
        dataIndex: 'vat',
        slots: {customRender: 'vat'}
      },
      {
        title: hsCodesColumns.value['col1']?.[_.snakeCase('colName')] || t(`models.priceCategory.attributes.${priceListApprovalColumns.col1}`),
        dataIndex: 'col1'
      },
      {
        title: hsCodesColumns.value['col2']?.[_.snakeCase('colName')] || t(`models.priceCategory.attributes.${priceListApprovalColumns.col2}`),
        dataIndex: 'col2'
      },
      {
        title: hsCodesColumns.value['col3']?.[_.snakeCase('colName')] || t(`models.priceCategory.attributes.${priceListApprovalColumns.col3}`),
        dataIndex: 'col3'
      },
      {
        title: hsCodesColumns.value['col4']?.[_.snakeCase('colName')] || t(`models.priceCategory.attributes.${priceListApprovalColumns.col4}`),
        dataIndex: 'col4'
      },
      {
        title: hsCodesColumns.value['col5']?.[_.snakeCase('colName')] || t(`models.priceCategory.attributes.${priceListApprovalColumns.col5}`),
        dataIndex: 'col5'
      },
      {
        title: hsCodesColumns.value['col6']?.[_.snakeCase('colName')] || t(`models.priceCategory.attributes.${priceListApprovalColumns.col6}`),
        dataIndex: 'col6'
      },
      {
        title: ''
      }
    ]);

    return {
      columns,
      prices,
      hsCodesColumns
    };
  }
});
</script>
