<template>
  <sdCards v-if="rejectionReason" headless>
    <h3>{{$t('models.companyProfileStep.attributes.rejectionReason')}}:</h3>
    {{rejectionReason}}
  </sdCards>
  <sdCards headless>
    <a-row justify="center">
      <a-col :xs="24">
        <div class="atbd-finish-order" :style="{ width: '100%' }">
          <sdHeading as="h4">{{$t('views.registration.termsAndConditions')}}</sdHeading>
          <a-checkbox :disabled="true" :checked="checked">
            <span class="checkbox-label">
              {{$t('views.profileVerification.termsAndConditions')}}
            </span>
          </a-checkbox>
        </div>
      </a-col>
    </a-row>
  </sdCards>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'TermsAndConditions',
  props: {
    checked: VueTypes.bool.def(false),
    rejectionReason: VueTypes.string.def(null)
  }
});
</script>

<style lang="scss" scoped>
.sDash_form-action button {
  border-radius: 4px;
  padding: 6.4px 29.2px;
  height: 44px;
}

@media only screen and (max-width: 500px){
  .sDash_form-action button,
  .sDash_form-action span {
    width: 100% !important;
  }
}
</style>
