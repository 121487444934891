<template>
  <table-style-wrapper>
    <table-wrapper class="table-responsive">
      <a-table
        :columns="columns"
        :data-source="prices"
        :pagination="false"
        :rowKey="(record) => record._jv.id"
      >
      <template #percent="{record}">
        {{`% ${record.percent}`}}
      </template>
      </a-table>
    </table-wrapper>
  </table-style-wrapper>
</template>

<script>
import {
  computed, defineComponent
} from 'vue';
import {TableStyleWrapper} from '@/components/tables/style.js';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {useI18n} from 'vue-i18n';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';

export default defineComponent({
  name: 'PriceListTable',
  components: {
    TableWrapper,
    TableStyleWrapper
  },
  props: {
    currentCountry: VueTypes.string,
    currentCategory: VueTypes.string
  },
  setup(props, context) {
    const {getters} = useStore();
    const {t} = useI18n();

    const prices = computed(() => {
      if (props.currentCountry && props.currentCategory) {
        const priceList = getters.companyPriceLists;
        return priceList[props.currentCountry][props.currentCategory];
      } else {
        return [];
      }
    });

    const columns = computed(() => [
      {
        title: t('models.suggestedPrice.attributes.priceCategoryItemId'),
        dataIndex: '_jv.relationships.priceCategoryItem.itemName'
      },
      {
        title: t('models.priceList.attributes.min'),
        dataIndex: 'min'
      },
      {
        title: t('models.suggestedPrice.attributes.suggestedMin'),
        dataIndex: '_jv.relationships.suggestedPrice.suggestedMin'
      },
      {
        title: t('models.priceList.attributes.percent'),
        dataIndex: 'percent',
        slots: {customRender: 'percent'}

      },
      {
        title: t('models.suggestedPrice.attributes.suggestedPercent'),
        dataIndex: '_jv.relationships.suggestedPrice.suggestedPercent'
      },
      {
        title: t('models.priceList.attributes.comment'),
        dataIndex: 'comment'
      },
      {
        title: ''
      }
    ]);

    return {
      columns,
      prices
    };
  }
});
</script>
