<template>
  <app-modal
    :title="$t('views.profileVerification.overallProfileStatus')"
    type="primary"
    :visible="visible"
    :onCancel="closeModal">
      <a-radio-group v-model:value="decision">
        <a-radio :value="status">
          {{$t('views.profileVerification.submitDecision', {decision: status})}}
        </a-radio>
        <a-radio value="blacklisted">
          {{$t('views.profileVerification.blackList')}}
        </a-radio>
      </a-radio-group>
      <div class="ant-popover-buttons">
        <a-button @click="closeModal" type="default">
          {{$t('actions.cancel')}}
        </a-button>
        <a-button @click="handleSubmit" type="primary" :loading="loading" :disabled="loading">
          {{$t('actions.submit')}}
        </a-button>
      </div>
  </app-modal>
</template>

<script>
import {
  defineComponent, ref, toRef, watch
} from 'vue';
import {useRouter} from 'vue-router';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';
import AppModal from '@/components/modals/Modal';

export default defineComponent({
  name: 'Modal',
  emits: ['closeModal', 'submitForm', 'update:resetForm', 'forceUpdated'],
  components: {AppModal},
  props: {
    visible: VueTypes.bool.def(false),
    status: VueTypes.string,
    companyId: VueTypes.string,
    companyType: VueTypes.string
  },
  setup(props, context) {
    const status = toRef(props, 'status');
    const decision = ref();
    watch(status, (value) => {
      if (value) decision.value = value;
    }, {immediate: true});

    const {dispatch} = useStore();
    const router = useRouter();

    const loading = ref(false);
    const handleSubmit = async () => {
      loading.value = true;
      const status = await dispatch('updateCompany', {
        form: {status: decision.value},
        id: props.companyId
      });
      if (status === 200) router.push({name: `BackOffice${props.companyType}Companies`});
      loading.value = false;
    };

    const closeModal = () => {
      decision.value = status.value;
      context.emit('closeModal');
    };

    return {
      decision,
      loading,
      handleSubmit,
      closeModal
    };
  }
});
</script>

<style lang="scss" scoped>
.ant-modal-body {
  padding-top: 0px !important;
}
</style>
