<template>
  <a-row :gutter="[32,0]">
    <a-col class="mt-10" :xs="24" v-if="rejectionReason">
      <sdCards headless>
        <h3>{{$t('models.companyProfileStep.attributes.rejectionReason')}}:</h3>
        {{rejectionReason}}
      </sdCards>
    </a-col>
    <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <sdCards :title="$t('views.registration.companyDetails')" icon="clipboard">
        <ul>
          <li v-for="field in companyDetailsFields" :key="field">
            <sd-heading as="h5" class="field-name">{{$t(`models.company.attributes.${field}`) + ':'}}</sd-heading>
            <p>{{company[field]}}</p>
          </li>
          <li>
            <sd-heading as="h5" class="field-name">{{$t('models.company.attributes.phone') + ':'}}</sd-heading>
            <p>{{`(${company['phoneCode']}) ${company['phone']}`}}</p>
          </li>
          <li>
            <sd-heading as="h5" class="field-name">{{$t('models.user.attributes.email') + ':'}}</sd-heading>
            <p>{{company['primaryUserEmail']}}</p>
          </li>
          <template v-if="company.type === 'Agent'">
            <li v-for="attribute in ['serviceTypes', 'productCategories']" :key="attribute">
              <sd-heading as="h5" class="field-name">{{$t(`models.company.attributes.${attribute}`) + ':'}}</sd-heading>
              <a-tag v-for="type in company[attribute]" :key="type">{{type.value || type}}</a-tag>
            </li>
          </template>
        </ul>
      </sdCards>
    </a-col>
    <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <a-row>
        <a-col :xs="24">
          <sdCards :title="$t('views.registration.address')" icon="map-pin">
            <ul>
              <li v-for="field in addressFields" :key="field">
                <sd-heading as="h5" class="field-name">{{$t(`models.company.attributes.${field}`) + ':'}}</sd-heading>
                <p>{{addressFieldLabel(field)}}</p>
              </li>
            </ul>
          </sdCards>
        </a-col>
        <a-col :xs="24">
          <sdCards :title="$t('views.profileVerification.uploadedDocuments')" icon="archive">
            <ul>
              <li v-for="field in uploadedDocuments" :key="field">
                <sd-heading as="h5" class="field-name">{{$t(`models.company.attributes.${field}`) + ':'}}</sd-heading>
                <a @click="download(company[field])">{{company[field] ? company[field]['filename'] : '--'}}</a>
              </li>
            </ul>
          </sdCards>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {downloadFile} from '@/helpers/downloadFile';
import {getCityByCode} from '@/helpers/citiesOptions';

export default defineComponent({
  name: 'CompanyDetailsCard',
  props: {
    company: VueTypes.object,
    rejectionReason: VueTypes.string.def(null)
  },
  setup(props) {
    const companyDetailsFields = ['name', 'contactPersonName', 'contactPersonPosition', 'registrationNumber'];
    const addressFields = ['country', 'city', 'street'];
    const uploadedDocuments = ['businessRegistrationCertificate', 'tradeLicense'];

    const download = (file) => {
      if (!file) return;
      downloadFile(file.filename, file.url);
    };

    const addressFieldLabel = (field) => {
      switch (field) {
      case 'country':
        return props.company['countryLabel'];
      case 'city':
        return getCityByCode(props.company['country'], props.company['city']);
      default:
        return props.company[field];
      }
    };

    return {
      companyDetailsFields,
      addressFields,
      uploadedDocuments,
      download,
      addressFieldLabel
    };
  }
});
</script>

<style lang="scss" scoped>
.field-name {
  font-size: 16px;
  display: inline-block;
  padding-right: 10px;
}

p {
  display: inline-block;
}
</style>
