<template>
  <div :class="`${view === 'backOfficeVerification' ? 'back-office-verification' : ''}`">
    <a-input-search class="sanctioned-countries-search"
          v-model:value="searchValue" :placeholder="$t('actions.search')"
    />
    <perfect-scrollbar ref="scrollbarRef" class="always-active-scrollbar"
      :options="{ wheelSpeed: 2, swipeEasing: true, suppressScrollX: true }">
      <TableWrapper>
        <div class="ant-table-content pb-30">
          <table class="ant-table">
            <tbody>
              <tr v-for="country in refinedCountries" :key="country.value">
                <td scope="row">
                  <div class="user-info">
                    <country-flag :country="country.value" size="normal" :rounded="true" />
                    <span class="country-label">{{country.label}}</span>
                  </div>
                </td>
                <td v-if="view !== 'backOfficeVerification'">
                  <div
                    v-if="view === 'agentRegistration' || $can('manage', 'sanctionedCountry')"
                    class="list-item-actions"
                  >
                    <a
                      @click="listFunction === 'remove' ? handleRemoveCountry(country) :
                        handleAddCountry(country.value)"
                    >
                      <a-tooltip
                        v-if="listFunction === 'add' && !disableAdd"
                        :title="view === 'agentRegistration' ? $t('actions.add') :
                          $t('views.sanctionedCountries.moveToSanctioned')"
                      >
                        <sdFeatherIcons type="arrow-right" :size="20" />
                      </a-tooltip>
                      <a-tooltip v-else-if="listFunction === 'remove'"
                        :title="view === 'agentRegistration' ? $t('actions.remove') :
                        $t('views.sanctionedCountries.removeFromSanctioned')"
                      >
                        <sdFeatherIcons type="arrow-left" :size="20" />
                      </a-tooltip>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>
    </perfect-scrollbar>
  </div>
</template>

<script>
import {
  computed, defineComponent, ref, nextTick, watch
} from 'vue';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import CountryFlag from 'vue-country-flag-next';
import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import VueTypes from 'vue-types';
import _ from 'lodash';

export default defineComponent({
  name: 'CountriesList',
  components: {
    TableWrapper,
    CountryFlag,
    PerfectScrollbar
  },
  emits: ['addCountry', 'removeCountry'],
  props: {
    countries: VueTypes.array,
    listFunction: VueTypes.oneOf(['add', 'remove']),
    view: VueTypes.oneOf(['sanctionedCountries', 'agentRegistration', 'backOfficeVerification']),
    disableAdd: VueTypes.bool.def(false)
  },
  setup(props, context) {
    const searchValue = ref('');
    const refinedCountries = computed(() => {
      const countries = _.filter(props.countries, (country) => {
        return country.label.toLowerCase().indexOf(searchValue.value.toLowerCase()) >= 0;
      });
      return _.orderBy(countries, (country) => country.label);
    });

    const scrollbarRef = ref();
    watch(refinedCountries, async () => {
      if (!_.isEmpty(refinedCountries.value)) {
        await nextTick();
        // Fix scrollbar not showing unless user actually scrolls
        setTimeout(() => scrollbarRef.value?.update(), 1);
      }
    });

    const handleAddCountry = (countryCode) => context.emit('addCountry', countryCode);
    const handleRemoveCountry = (countryCode) => context.emit('removeCountry', countryCode);

    return {
      searchValue,
      refinedCountries,
      scrollbarRef,
      handleAddCountry,
      handleRemoveCountry
    };
  }
});
</script>


<style lang="scss" scoped>
.ant-card-body {
  text-align: center !important;
}

.ps {
  height: 70vh;
}

@media only screen and (max-width: 500px){
  .ps {
    height: 25vh;
  }
}

.sanctioned-countries-search {
  width: 90% !important;
  margin: 0px 20px !important;
  padding-block: 11px;
  border-radius: 10px !important;
}

.country-label {
  line-height: 2 !important;
}

.back-office-verification {
  text-align: center;
}

.back-office-verification {
  padding-top: 20px;
}

.back-office-verification .ant-table {
  width: 100%;
}

.back-office-verification .always-active-scrollbar {
  padding-top: 20px;
  padding-inline: 20px;
}
</style>
