<template>
  <modal-form
    :key="`step_${current}`"
    :title="$t('views.profileVerification.rejectionReason')"
    :fields="fields"
    :loading="loading"
    :visible="modalVisible"
    :cancelButton="{type: 'secondary', visible: true}"
    @close-modal="modalVisible = false"
    @submit-form="rejectStep"
  />
  <decision-modal
    :visible="decisionModalVisible"
    :status="overallStatus"
    :companyId="company._jv?.id"
    :companyType="company.type"
    @close-modal="decisionModalVisible = false"
  />
  <div>
    <app-header :routes="routes" />
    <Main>
      <a-row :gutter="15">
        <a-col :xs="24">
          <sdCards headless>
            <basic-steps step="withIcon" size="default" :data="steps" :current="current" />
          </sdCards>
        </a-col>
        <a-col :xs="24" v-if="!$_.isEmpty(steps)">
          <company-details
            v-if="steps[current].id === companyProfileSteps.COMPANY_DETAILS"
            :rejectionReason="steps[current].reason"
            :company="company"
          />
          <product-items-table
            v-if="company._jv && steps[current].id === companyProfileSteps.PRODUCT_ITEMS"
            :rejectionReason="steps[current].reason"
            :companyId="company._jv.id"
          />
          <countries-coverage
            v-if="steps[current].id === companyProfileSteps.COUNTRIES_COVERAGE"
            :rejectionReason="steps[current].reason"
            :countries="company.labelledCountriesCoverage"
          />
          <price-lists
            ref="priceListRef"
            v-if="steps[current].id === companyProfileSteps.PRICE_LISTS"
            :companyId="company._jv.id"
            @next="current++"
            @previous="current--"
            @updatePriceListStatuses="updatePriceListStatuses"
          />
          <agreements
            v-if="steps[current].id === companyProfileSteps.AGREEMENT"
            :rejectionReason="steps[current].reason"
            :checked="company ? company.termsAndConditions : false"
          />
        </a-col>

        <a-col :xs="24">
          <verification-actions
            :hasNext="hasNext"
            :hasPrevious="hasPrevious"
            :loading="loading"
            :currentStepStatus="currentStepStatus"
            :priceListStep="steps[current]?.id === companyProfileSteps.PRICE_LISTS"
            :priceListUnset="$_.some(priceListStatuses, ({status}) => status === 'unset')"
            @approve="approveStep"
            @reject="modalVisible = true"
            @next="nextStep"
            @previous="previousStep"
            @submitPriceListVerification="submitPriceLists"
          />
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import {
  computed, defineComponent, reactive, ref, watch
} from 'vue';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {useI18n} from 'vue-i18n';
import {Main} from '@/components/shared/styledComponents/Main';
import ModalForm from '@/components/modals/ModalForm';
import BasicSteps from '@/components/steps/Basic';
import {companyProfileSteps} from '@/constants/index';
import VerificationActions from '@/components/companyVerification/VerificationActions';
import CompanyDetails from '@/components/companyVerification/CompanyDetails';
import ProductItemsTable from '@/components/companyVerification/ProductItemsTable';
import CountriesCoverage from '@/components/companyVerification/CountriesCoverage';
import PriceLists from '@/components/companyVerification/PriceLists';
import Agreements from '@/components/companyVerification/Agreements';
import DecisionModal from '@/components/companyVerification/DecisionModal';
import _ from 'lodash';
import {required} from '@/helpers/validationRules';
import {displayServerErrorsInToaster} from '@/helpers/toasters';


export default defineComponent({
  name: 'ProfileVerification',
  components: {
    Main,
    ModalForm,
    BasicSteps,
    VerificationActions,
    CompanyDetails,
    ProductItemsTable,
    CountriesCoverage,
    PriceLists,
    Agreements,
    DecisionModal
  },
  setup() {
    const {t} = useI18n();
    const routes = [{
      name: 'BackOfficeProfileVerification',
      breadcrumbName: 'views.profileVerification.title'
    }];

    const clientSteps = [{
      id: companyProfileSteps.COMPANY_DETAILS, title: t('views.registration.companyDetails'), icon: 'home',
      description: ''
    },
    {
      id: companyProfileSteps.PRODUCT_ITEMS, title: t('views.registration.products'), icon: 'shopping-cart',
      description: ''
    },
    {
      id: companyProfileSteps.AGREEMENT, title: t('views.registration.termsAndConditions'), icon: 'clipboard',
      description: ''
    }];

    const agentSteps = [
      {
        id: companyProfileSteps.COMPANY_DETAILS, title: t('views.registration.companyDetails'), icon: 'home',
        description: ''
      },
      {
        id: companyProfileSteps.COUNTRIES_COVERAGE, title: t('views.registration.countriesCoverage'), icon: 'map',
        description: ''
      },

      {
        id: companyProfileSteps.PRICE_LISTS, title: t('views.registration.priceList'), icon: 'dollar-sign',
        description: ''
      },
      {
        id: companyProfileSteps.AGREEMENT, title: t('views.registration.termsAndConditions'), icon: 'clipboard',
        description: ''
      }
    ];

    const steps = ref([]);
    const current = ref(0);
    const hasNext = computed(() => !!steps.value[current.value + 1]);
    const hasPrevious = computed(() => current.value > 0);
    const priceListRef = ref();
    const company = computed(() => getters.company);
    const profileSteps = computed(() => getters.companyProfileSteps);

    const {dispatch, getters} = useStore();
    const getCompany = async (id) => {
      await dispatch('toggleLoading', true);
      await Promise.all([
        dispatch('getCompany', id),
        dispatch('getCompanyProfileSteps', id)
      ]);
      checkIfVerificationRequired();

      steps.value = company.value.type === 'Agent' ? agentSteps : clientSteps;
      setStepsStatuses();
      await dispatch('toggleLoading', false);
    };

    const setStepsStatuses = () => {
      _.forEach(steps.value, (step) => {
        const status = _.find(profileSteps.value.steps, (profileStep) => profileStep.name === step.id)?.status;
        step['description'] = !status || status === 'unset' ? '' : `(${status})`;
        const reason = _.find(profileSteps.value.steps, (profileStep) => profileStep.name === step.id)?.rejectionReason;
        step['reason'] = !reason ? '' : `${reason}`;
      });
    };

    const router = useRouter();
    const checkIfVerificationRequired = () => {
      if (_.includes(['cs_verification_required', 'in_review'], company.value.status)) return;

      displayServerErrorsInToaster([t('messages.error.alreadyVerified')]);
      router.push({name: 'CompanyProfile', params: {companyId: company.value._jv.id}});
    };

    const currentStepStatus = computed(() => {
      if (_.isEmpty(profileSteps.value.steps) || _.isEmpty(steps.value) ||
        steps.value[current.value].id === companyProfileSteps.PRICE_LISTS) {
        return 'unset';
      } else {
        return _.find(profileSteps.value.steps, (step) => step.name === steps.value[current.value].id).status;
      }
    });

    const loading = ref(false);
    const modalVisible = ref(false);
    const fields = {
      rejectionReason: {
        type: 'textArea',
        fieldValue: '',
        placeHolder: t('views.profileVerification.rejectionReasonPlaceholder'),
        rules: [required],
        class: 'mb-20'
      },
      status: {
        type: 'text',
        fieldValue: 'rejected',
        hidden: true,
        rules: [required],
        class: 'mb-20'
      }
    };

    const priceListStatuses = reactive({});
    const updatePriceListStatuses = (statuses) => Object.assign(priceListStatuses, statuses);

    const submitPriceLists = () => {
      const overallStatus = _.some(priceListStatuses, ({status}) => status === 'rejected') ? 'rejected' : 'approved';
      const data = {
        [_.snakeCase('priceListStepsAttributes')]: _.values(priceListStatuses),
        status: overallStatus
      };
      submitStep(data);
    };

    const approveStep = () => submitStep({status: 'approved'});
    const rejectStep = async (data) => {
      await submitStep(data);
      modalVisible.value = false;
    };

    const submitStep = async (form) => {
      const id = _.find(profileSteps.value.steps, (step) => step.name === steps.value[current.value].id)?._jv?.id;
      loading.value = true;
      const status = await dispatch('updateCompanyProfileStep', {
        id, form, companyId: company.value._jv.id
      });
      loading.value = false;

      if (status !== 200) return;

      setStepsStatuses();

      if (steps.value[current.value].id === companyProfileSteps.PRICE_LISTS) {
        priceListRef.value.handleNext();
      } else if (hasNext.value) {
        current.value++;
      }
    };

    const nextStep = () => {
      if (steps.value[current.value].id === companyProfileSteps.PRICE_LISTS) {
        priceListRef.value.handleNext();
      } else if (hasNext.value) {
        current.value++;
      } else {
        decisionModalVisible.value = true;
      }
    };

    const previousStep = () => {
      if (steps.value[current.value].id === companyProfileSteps.PRICE_LISTS) {
        priceListRef.value.handlePrevious();
      } else {
        current.value--;
      }
    };

    const route = useRoute();
    watch(() => route.params, ({companyId}) => {
      if (route.name === 'BackOfficeProfileVerification') getCompany(companyId);
    }, {immediate: true});

    const decisionModalVisible = ref(false);
    const overallStatus = computed(() => {
      return _.some(profileSteps.value.steps, {status: 'rejected'}) ? 'rejected' : 'approved';
    });

    return {
      routes,
      profileSteps,
      fields,
      steps,
      companyProfileSteps,
      current,
      hasNext,
      hasPrevious,
      company,
      modalVisible,
      loading,
      currentStepStatus,
      decisionModalVisible,
      rejectStep,
      approveStep,
      nextStep,
      previousStep,
      priceListRef,
      overallStatus,
      priceListStatuses,
      updatePriceListStatuses,
      submitPriceLists
    };
  }
});
</script>
