<template>
  <confirm-modal
    :title="$t('views.confirm.navigation.title')"
    :confirmMessage="$t('views.confirm.navigation.message')"
    :showConfirm="confirmModalVisible"
    @confirm:ok="$emit('previous')"
    @confirm:cancel="confirmModalVisible = false">
  </confirm-modal>
  <div class="sDash_form-action">
    <a-button
      v-if="hasPrevious"
      class="btn-signin mr-10"
      size="large"
      type="default"
      :loading="loading"
      :disabled="loading"
      @click="!priceListStep ? $emit('previous') : confirmModalVisible = true"
    >
      <span>{{$t('actions.previous')}}</span>
    </a-button>
    <span v-if="!priceListStep" class="float-button-right">
      <a-button
        v-if="currentStepStatus !== 'rejected'"
        class="btn-signin mr-10"
        size="large"
        type="default"
        :loading="loading"
        :disabled="loading"
        @click="$emit('reject')"
      >
        <span>{{$t('actions.reject')}}</span>
      </a-button>
      <a-button
        v-if="currentStepStatus !== 'approved'"
        class="btn-signin mr-10"
        size="large"
        type="primary"
        :loading="loading"
        :disabled="loading"
        @click="$emit('approve')"
      >
        <span>{{$t('actions.approve')}}</span>
      </a-button>
      <a-button
        v-if="currentStepStatus !== 'unset'"
        class="btn-signin mr-10"
        size="large"
        type="default"
        :loading="loading"
        :disabled="loading"
        @click="$emit('next')"
      >
        <span>{{hasNext ? $t('actions.next') : $t('actions.finish')}}</span>
      </a-button>
    </span>
    <span v-else class="float-button-right">
      <a-button
        class="btn-signin mr-10"
        size="large"
        type="default"
        :loading="loading"
        :disabled="priceListUnset || loading"
        @click="$emit('submitPriceListVerification')"
      >
        <span>{{$t('actions.saveAndNext')}}</span>
      </a-button>
    </span>

  </div>
</template>

<script>
import {defineComponent, ref} from 'vue';
import VueTypes from 'vue-types';
import ConfirmModal from '@/components/modals/ConfirmModal';

export default defineComponent({
  name: 'CompanyVerificationActions',
  emits: ['approve', 'reject', 'next', 'previous', 'submitPriceListVerification'],
  components: {ConfirmModal},
  props: {
    loading: VueTypes.bool.def(false),
    hasNext: VueTypes.bool.def(false),
    hasPrevious: VueTypes.bool.def(false),
    priceListStep: VueTypes.bool.def(false),
    priceListUnset: VueTypes.bool.def(false),
    currentStepStatus: VueTypes.string
  },
  setup() {
    const confirmModalVisible = ref(false);
    return {
      confirmModalVisible
    };
  }
});
</script>

