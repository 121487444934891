<template>
  <sdCards v-if="rejectionReason" headless >
    <h3>{{$t('models.companyProfileStep.attributes.rejectionReason')}}:</h3>
    {{rejectionReason}}
  </sdCards>
  <sdCards headless>
    <countries-list
      :countries="countries"
      view="backOfficeVerification"
    />
  </sdCards>
</template>

<script>
import {defineComponent} from 'vue';
import CountriesList from '@/components/shared/cards/CountriesList';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'CountriesCoverage',
  components: {
    CountriesList
  },
  props: {
    countries: VueTypes.array.def([]),
    rejectionReason: VueTypes.string.def(null)
  }
});
</script>
